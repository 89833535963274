import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';

import {
  Box,
  Grid,
  Tab,
  Tabs,
  Paper,
  Button,
  Typography,
} from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';

import { makeStyles } from '@material-ui/core/styles';

import StationChart from './StationChart';
import StationDataToggleButtons from './StationDataToggleButtons';
import useToggleState from './useToggleState';
import { availableDataModes, getThresholdsOrdered } from '../helpers/commonHelpers';
import StationDashboardSummary from './StationDashboardSummary';
import CustomDatePicker from './CustomDatePicker';
import PrintButton from './PrintButton';
import ChartLegend from './ChartLegend';

const useStyles = makeStyles(theme => ({
  stdPaper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    flex: '1 1 auto',
  },
  chartsWrapper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2, 2),
  },
  stationChart: {
    marginTop: theme.spacing(2),
  },
  datePicker: {
    padding: theme.spacing(0, 2),
  },
  exportButton: {
    margin: theme.spacing(0, 2, 0, 0),
    float: 'right',
  },
  compareButton: {
    margin: theme.spacing(1),
    float: 'right',
  },
  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
  },
}));

const isLive = typeof window !== 'undefined';

const StationDashboard = ({ stationProperties, vcn3, vcn10 }) => {
  const classes = useStyles();

  const currentAvailableDataModes = React.useMemo(
    () => availableDataModes(stationProperties.quantities),
    [stationProperties],
  );
  const [
    activeDataModeId,
    handleDataModeChange,
    activeAnteriorityId,
    handleAnteriorityChange,
  ] = useToggleState(currentAvailableDataModes);

  const [activeTab, setActiveTab] = React.useState(0);
  const handleTabChange = (event, newValue) =>
    setActiveTab(newValue);

  const selectedDate = useSelector(({ mainDate }) => mainDate);
  const stationCode = stationProperties.code;

  const graphicDomId = `stationpagegraph${stationCode}`;

  // Prepare filename for export.
  const selectedDateObject = new Date(selectedDate);
  const fileBaseName = `lvdlr_${stationCode}_${activeDataModeId}_${selectedDateObject.toLocaleDateString()}`;
  const thresholds = React.useMemo(
    () => getThresholdsOrdered(stationProperties.thresholds),
    [stationProperties.thresholds],
  );

  return (
    <>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={6} container>
          <Paper className={classes.stdPaper}>
            <StationDashboardSummary properties={stationProperties} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid item>
            <Paper className={classes.stdPaper}>
              <Box className={classes.datePicker}>
                <CustomDatePicker label="Période se terminant le" />
              </Box>
            </Paper>
          </Grid>
          <Grid item>
            <Paper className={classes.stdPaper}>
              <StationDataToggleButtons
                activeDataModeId={activeDataModeId}
                handleDataModeChange={handleDataModeChange}
                activeAnteriorityId={activeAnteriorityId}
                handleAnteriorityChange={handleAnteriorityChange}
                availableDataModes={currentAvailableDataModes}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Button
        size="small"
        color="primary"
        variant="contained"
        className={classes.compareButton}
        endIcon={<BarChartIcon />}
      >
        <Link to="/compare" state={{ stationProperties }} className={classes.link} activeClassName="current">
          Comparer aux autres stations
        </Link>
      </Button>

      {Boolean(!activeTab) && (
        <div className={classes.exportButton}>
          <PrintButton
            elementConfigurations={[{ targetQuerySelector: `#${graphicDomId}`, scale: 1.5 }]}
            fileBaseName={fileBaseName}
          />
          <Typography variant="caption">
            Exporter le graphique
          </Typography>
        </div>
      )}

      <Box className={classes.tabs}>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          <Tab label="Graphique" />
          <Tab label="Tableau" />
        </Tabs>
      </Box>

      {Boolean(isLive) && (
        <>
          <Box id={graphicDomId} className={classes.stationChart}>
            <StationChart
              id={stationProperties.code}
              mode={activeDataModeId}
              anteriorityId={activeAnteriorityId}
              table={Boolean(activeTab)}
              endDate={selectedDate}
              thresholds={thresholds}
              hasDailyQuantiles={stationProperties.has_daily_quantiles}
              vcn3={vcn3} 
              vcn10={vcn10}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default React.memo(StationDashboard);
