import React from 'react';

import { frontAnteriorityConfigs } from '../helpers/commonHelpers';

const useToggleState = availableDataModes => {
  const initDataModes = availableDataModes.find(({ disable }) => !disable);
  const [activeDataModeId, setActiveDataModeId] = React.useState(initDataModes.id);
  const handleDataModeChange = React.useCallback(
    (event, newValue) => {
      // when clicking on the current active button, newValue is set to null
      // we keep activeDataModeId as the dataModes id to have consistency between
      // StationChart mode expected input and ToggleButton value that also uses
      // this setActiveDataModeId function to detect the active button and style it
      if (newValue != null) {
        setActiveDataModeId(newValue);
      }
    },
    [],
  );

  const firstAnteriorityConfig = frontAnteriorityConfigs[0];
  const [activeAnteriorityId, setActiveAnteriorityId] = React.useState(firstAnteriorityConfig.id);
  const handleAnteriorityChange = React.useCallback(
    (event, newValue) => {
      // when clicking on the current active button, newValue is set to null
      if (newValue != null) {
        setActiveAnteriorityId(newValue);
      }
    },
    [],
  );

  // pass in the user variable as a property, along with
  // all the other props that we might be given
  return [activeDataModeId, handleDataModeChange, activeAnteriorityId, handleAnteriorityChange];
};

export default useToggleState;
