import React from 'react';
import { graphql } from 'gatsby';

import { Typography } from '@material-ui/core';

import Layout from '../components/Layout';
import Link from '../components/Link';

import StationDashboard from '../components/StationDashboard';

const StationPage = ({
  data: { station: { json: { properties: jsonProperties }, vcn3 = [], vcn10 = [] } },
}) => {
  const properties = React.useMemo(() => JSON.parse(jsonProperties), [jsonProperties]);

  return (
    <Layout title={`Station ${properties.label}`} noFooter>
      <Typography variant="body2" gutterBottom>
        <Link to="/">‹ Retour à la carte</Link>
      </Typography>

      <Typography variant="h2" component="h1" gutterBottom>
        {properties.label}
      </Typography>

      <StationDashboard stationProperties={properties} vcn3={vcn3} vcn10={vcn10} />
    </Layout>
  );
};

export default StationPage;

export const pageQuery = graphql`
  query MyQuery($id: String) {
    station: geojsonFeature(id: { eq: $id }) {
      json { properties }
      vcn3 {
        d
        v
        y
      }
      vcn10 {
        d
        v
        y
      }
    }
  }
`;
